import $ from 'jquery';
$(document).on('ready turbolinks:load', function () {
  let current_pathname = location.pathname;
  const regex = /^\/clients\/(\d+)\/edit$/;
  if (current_pathname.match(regex)) {
    let bill_target_type_input = 'input[type="radio"][name="client[bill_target_type]"]';

    $(bill_target_type_input).change(function () {
      $('#warning-switch-bill-target-type').modal('show');
    });
  }

  $('.client-edit-work-pages .js-product-select,.client-edit-work-pages .js-sales-user-select').select2({
    width: '100%',
  });

  $('.client-edit-work-pages .js-format-float-field').each(function () {
    var value = $(this).val();
    if (value) {
      value = value.replace(/,/g, '');
      $(this).val(value.replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    }
  });
});